import React from "react";
import { FooterWrapper } from "./Footer.styles";
import { Footer } from "antd/lib/layout/layout";
import { Col, Row, Image } from "antd";
import AZFTLOGO from "../../assets/img/footer-logo.png";

const AZFooter = (props) => (
  <FooterWrapper>
    <Footer className="footer">
      <section className="ant-layout" style={{ backgroundColor: "#000000" }}>
        <div className="ant-row">
          <div className="ant-col ant-col-12">
            <div className="ant-image" style={{ height: "20px" }}>
              <Image className="AZLogo" height={20} preview={false} src={AZFTLOGO} />
            </div>
          </div>
          <div className="ant-col ant-col-12" style={{ textAlign: "right", color: "#ffffff" }}>
            © Astrazeneca 2022
          </div>
        </div>

      </section>
    </Footer>
  </FooterWrapper>
);

export default AZFooter;
