
export default [
    {
        name: "Total Dismissed",
        color: "#FF0000"
    },
    {
        name: "Total Actioned",
        color: "#009933"
    },
    {
        name: "Total Expired",
        color: "#800000"
    },
    {
        name: "Total Pending",
        color: "#ff6600"
    },

]