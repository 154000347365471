import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";

const DonutChart = (props) => {
    const DONUT_CHART_ID = "donutChart-" + props.title.replace(" ", "-");
    const donutChart = useRef(null);
    useEffect(() => {
        let root = am5.Root.new("donutChart-" + props.title.replace(" ", "-"));

        donutChart.current = root;

        if (props.response.length > 0)
            loadDonutChart(root);
        else
            root.dispose();
        return () => {
            root.dispose();
        };
    }, []);

    const loadDonutChart = (root) => {

        let data = [
            {
                "name": "F",
                "count": 59934
            },
            {
                "name": "M",
                "count": 59914
            },
            {
                "name": "U",
                "count": 18
            }
        ]
        root.setThemes([am5themes_Animated.new(root)]);
        var chart = root.container.children.push(
            am5percent.PieChart.new(root, {
                layout: root.verticalLayout,
            })
        );

        // Create series
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
        var series = chart.series.push(
            am5percent.PieSeries.new(root, {
                alignLabels: true,
                calculateAggregates: true,
                valueField: "count",
                categoryField: "name",
                legendLabelText:
                    "{category} ([bold]{valuePercentTotal.formatNumber('0.0')}%[/])",
                legendValueText: "",
                innerRadius: 100,
            })
        );

        series.slices.template.setAll({
            strokeWidth: 3,
            stroke: am5.color(0xffffff),
        });

        series.labelsContainer.set("paddingTop", 30);

        // Set up adapters for variable slice radius
        // https://www.amcharts.com/docs/v5/concepts/settings/adapters/
        series.slices.template.adapters.add("radius", function (radius, target) {
            var dataItem = target.dataItem;
            var high = series.getPrivate("valueHigh");

            if (dataItem) {
                var value = target.dataItem.get("valueWorking", 0);
                //return (radius * value) / high;
            }
            return radius;
        });
        series.labels.template.setAll({
            fontSize: 14,
            fill: am5.color(0x000000),
            text:
                "{category}: [bold]{valuePercentTotal.formatNumber('0.0')}%[/] ({value})",
        });

        // Set data
        // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
        series.data.setAll(data);

        // Create legend
        // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
        var legend = chart.children.push(
            am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                marginTop: 15,
                marginBottom: 15,
            })
        );

        legend.data.setAll(series.dataItems);

        // Play initial series animation
        // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
        series.appear(1000, 100);
    }

    return (
        <>
            {props.response && <div id={DONUT_CHART_ID} style={{
                width: '100%',
                height: '500px',
                marginTop: "30px",
                marginLeft: "30px",
                marginRight: "30px"
            }}></div>
            }
        </>
    )
}
export default DonutChart;