const API_BASE_URL = process.env.REACT_APP_BASE_URL;
const API_URL = API_BASE_URL + "/dashboard/api/v2";
const appDefaults = {
  api: {
    baseURL: API_BASE_URL,
    auth: API_BASE_URL + "/identity/ping/login",
    getTherapeuticalArea: API_URL + "/therapeuticalarea",
    getDrugs: API_URL + "/drugs",
    getCycles: API_URL + "/triggermetric/ta",
    getInsights: API_URL + "/triggermetric/ta",
    getDistributions: API_URL + "/distributions/ta",
    getTriggerTypes: API_URL + "/triggermetric/summarylist",
    getTriggerList: API_URL + "/triggermetric/summary/list/",
    getTriggerByType: API_URL + "/triggermetric",
    getTriggersCycle: API_URL + "/triggermetric/compare/cycles"
  },
};
export default appDefaults;

export const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const ITEM_CLASS = {
  animation: ["gif", "swf"],
  audio: ["mp1", "mp2", "mp3", "wav"],
  images: ["bmp", "jpeg", "jpg", "png"],
  mixed: ["docx", "doc", "pdf", "rtf", "ppt", "pptx", "csv", "txt"],
  video: ["3gp", "3g2", "mp4", "mpe", "mpeg"],
};
