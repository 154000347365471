import { Layout, Select, Space } from "antd";
import React, { useEffect, useState, useContext } from "react";
import Brand from "../Brand";
import UserInfo from "../UserInfo";
import { HeaderWrapper } from "./Header.styles";
import { GetAPIRequest } from "../../services";
import CONFIG from "../../constants";
import HeaderContext from "../../store/HeaderContext";


const { Header } = Layout;
const { Option } = Select;
const AZHeader = (props) => {

  const [therapeuticalArea, setTherapeuticalArea] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState('');
  const [selectedTherapeuticalArea, setSelectedTherapeuticalArea] = useState("SLE");

  useEffect(() => {
    getTherapeuticalArea();
  }, [props.isTaAndDrugDisable]);

  useEffect(() => {
    props.getHeaderComponentValues(selectedTherapeuticalArea, selectedDrug);
  }, [selectedTherapeuticalArea, selectedDrug])

  function getTherapeuticalArea() {
    GetAPIRequest({
      url: CONFIG.api.getTherapeuticalArea,
    }).then((response) => {
      if (response) {

        setTherapeuticalArea(response);
        if (selectedTherapeuticalArea)
          getDrugs(selectedTherapeuticalArea);
        else
          getDrugs(response[0]);
        console.log("_response", response);
      }
    });
  }

  function getDrugs(ta = "SLE") {
    sessionStorage.setItem("eosValue", ta);
    GetAPIRequest({
      url: `${CONFIG.api.getDrugs}/${ta}`,
    }).then((response) => {
      if (response) {
        sessionStorage.setItem("drugName", response[0]);
        setDrugs(response);
        setSelectedDrug(response[0]);
        //  getCycles(ta);
        console.log("_response", response);
        console.log("selectedDrug", selectedDrug);
      }
    });
  }

  return (
    <HeaderWrapper>

      <Header className="header">
        <Brand />
        {/* <Space> */}
        <span style={{ margin: "10px 10px 10px 10px" }}>Drug Area :</span><Select
          disabled={props.isTaAndDrugDisable}
          showSearch
          className="filterBox"
          placeholder="Search to Select"
          optionFilterProp="children"
          value={selectedTherapeuticalArea}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }
          onChange={(newValue) => {
            sessionStorage.removeItem("cycleId")
            setSelectedTherapeuticalArea(newValue);
            getDrugs(newValue);
          }}
        >
          {therapeuticalArea.map((ta, idx) =>
          (
            <Option key={idx} value={ta}>
              {ta}
            </Option>
          )
          )}
        </Select>
        <span style={{ margin: "10px 10px 10px 10px" }}>Drug Name : </span>   <Select
          disabled={props.isTaAndDrugDisable}
          showSearch
          className="filterBox"
          onChange={(newValue) => {
            sessionStorage.removeItem("cycleId")
            setSelectedDrug(newValue);
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          value={selectedDrug}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children
              .toLowerCase()
              .localeCompare(optionB.children.toLowerCase())
          }

        >
          {drugs.map((drug, idx) => (
            <Option key={idx} value={drug}>
              {drug}
            </Option>
          ))}
        </Select>
        {/* </Space> */}
        <UserInfo />
      </Header>
    </HeaderWrapper>
  );
};

export default AZHeader;
