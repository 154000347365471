import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const HBarChart = (props) => {

    const HBAR_CHART_ID = "hBarChart-" + props.title.replace(" ", "-");
    const barChart = useRef(null);
    let data = [
        {
            "name": "BREATHING_TROUBLE/UPPER_RESPIRATORY_TRACT_INFECTION",
            "count": 0.02
        },
        {
            "name": "PAIN",
            "count": 0.02
        },
        {
            "name": "HIGH_BLOOD_PRESSURE",
            "count": 0.02
        },
        {
            "name": "FATIGUE",
            "count": 0.01
        },
        {
            "name": "COUGH",
            "count": 0.01
        },
        {
            "name": "ANEMIA",
            "count": 0.01
        },
        {
            "name": "DIABETES",
            "count": 0.01
        },
        {
            "name": "NAUSEA_VOMITING",
            "count": 0.01
        },
        {
            "name": "SKIN_RASH",
            "count": 0.01
        },
        {
            "name": "SWELLING",
            "count": 0.01
        },
        {
            "name": "CONSTIPATION",
            "count": 0.01
        },
        {
            "name": "DIARRHOEA",
            "count": 0
        },
        {
            "name": "PNEUMONIA",
            "count": 0
        },
        {
            "name": "HYPERCHOLESTEROLEMIA",
            "count": 0
        },
        {
            "name": "HYPOTHYROIDISM",
            "count": 0
        },
        {
            "name": "LOW_BLOOD_CELL_COUNTS",
            "count": 0
        },
        {
            "name": "NEUROPATHY",
            "count": 0
        },
        {
            "name": "URINARY_TRACT_DISORDERS",
            "count": 0
        },
        {
            "name": "ARTHRALGIA",
            "count": 0
        },
        {
            "name": "PERIPHERAL_SENSORY_NEUROPATHY",
            "count": 0
        },
        {
            "name": "RASH",
            "count": 0
        },
        {
            "name": "COLITIS",
            "count": 0
        },
        {
            "name": "HEPATITIS",
            "count": 0
        },
        {
            "name": "HYPOXEMIA",
            "count": 0
        },
        {
            "name": "MUCOSITIS",
            "count": 0
        },
        {
            "name": "ORAL_ULCER",
            "count": 0
        },
        {
            "name": "SOFT_TISSUE_DISORDER",
            "count": 0
        },
        {
            "name": "BLEEDING",
            "count": 0
        },
        {
            "name": "CHILLS/FEVER",
            "count": 0
        },
        {
            "name": "INCREASED_ALKALINE_PHOSPHATASE",
            "count": 0
        },
        {
            "name": "NICOTINE_DEPENDENCE",
            "count": 0
        },
        {
            "name": "AUTOIMMUNE_DIABETES",
            "count": 0
        },
        {
            "name": "DRY_SKIN",
            "count": 0
        },
        {
            "name": "DYSGEUSIA",
            "count": 0
        },
        {
            "name": "HEADACHE",
            "count": 0
        },
        {
            "name": "HYPERTHYROIDISM",
            "count": 0
        },
        {
            "name": "MUSCULOSKELETAL_PAIN",
            "count": 0
        },
        {
            "name": "MYOCARDITIS",
            "count": 0
        },
        {
            "name": "PROTEINURIA",
            "count": 0
        },
        {
            "name": "PRURITUS",
            "count": 0
        },
        {
            "name": "RHINITIS",
            "count": 0
        },
        {
            "name": "THROMBOCYTOPENIA",
            "count": 0
        }
    ]
    useEffect(() => {
        let root = am5.Root.new("hBarChart-" + props.title.replace(" ", "-"));

        barChart.current = root;

        if (props.response.length > 0)
            loadHBarChart(root);
        else
            root.dispose();
        return () => {
            root.dispose();
        };
    }, []);


    const loadHBarChart = (root) => {

        // var root = am5.Root.new("hBarChart-" + props.title.replace(" ", "-"));

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);

        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
            })
        );

        // We don't want zoom-out button to appear while animating, so we hide it
        chart.zoomOutButton.set("forceHidden", true);

        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30,
        });
        yRenderer.labels.template.setAll({
            fontSize: "14px",
        });

        var yAxis = chart.yAxes.push(
            am5xy.CategoryAxis.new(root, {
                maxDeviation: 0,
                categoryField: "name",
                renderer: yRenderer,
                tooltip: am5.Tooltip.new(root, { themeTags: ["axis"] }),
            })
        );

        var xRenderer = am5xy.AxisRendererX.new(root, {});

        var xAxis = chart.xAxes.push(
            am5xy.ValueAxis.new(root, {
                maxDeviation: 0,
                min: 0,
                extraMax: 0.1,
                renderer: xRenderer,
            })
        );
        xRenderer.labels.template.setAll({
            fontSize: "12px",
        });

        // Add series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(
            am5xy.ColumnSeries.new(root, {
                name: "Series 1",
                xAxis: xAxis,
                yAxis: yAxis,
                valueXField: "count",
                categoryYField: "name",
                tooltip: am5.Tooltip.new(root, {
                    pointerOrientation: "left",
                    labelText: "{valueX}[/]",
                }),
            })
        );
        var columnTemplate = series.columns.template;

        columnTemplate.setAll({
            draggable: true,
            cursorOverStyle: "pointer",
            tooltipText: "Drag to rearrange",
            cornerRadiusBR: 5,
            cornerRadiusTR: 5,
        });

        columnTemplate.events.on("dragstop", () => {
            sortCategoryAxis();
        });

        // Make each column to be of a different color
        columnTemplate.adapters.add("fill", function (fill, target) {
            return am5.color(0x547fd4);
            //return chart.get("colors").getIndex(series.columns.indexOf(target));
            //return "#547DCF";
        });

        columnTemplate.adapters.add("stroke", function (stroke, target) {
            return am5.color(0x547fd4);
            //return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        yAxis.data.setAll(data);
        series.data.setAll(data);
        sortCategoryAxis();
        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "{valueX}",
                    populateText: true,
                    fontSize: "14px",
                }),
            });
        });

        // Get series item by category
        function getSeriesItem(category) {
            for (var i = 0; i < series.dataItems.length; i++) {
                var dataItem = series.dataItems[i];
                if (dataItem.get("categoryY") == category) {
                    return dataItem;
                }
            }
        }

        chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "none",
                xAxis: xAxis,
                yAxis: yAxis,
            })
        );
        columnTemplate.events.on("dragstop", () => {
            sortCategoryYAxis();
        });

        // Axis sorting
        function sortCategoryAxis() {
            // Sort by value
            series.dataItems.sort(function (x, y) {
                return x.get("valueX") - y.get("valueX"); // descending
                //return y.get("valueY") - x.get("valueX"); // ascending
            });

            // Go through each axis item
            am5.array.each(yAxis.dataItems, function (dataItem) {
                // get corresponding series item
                var seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                    // get index of series data item
                    var index = series.dataItems.indexOf(seriesDataItem);
                    // calculate delta position
                    var deltaPosition =
                        (index - dataItem.get("index", 0)) / series.dataItems.length;
                    // set index to be the same as series data item index
                    dataItem.set("index", index);
                    // set deltaPosition instanlty
                    dataItem.set("deltaPosition", -deltaPosition);
                    // animate delta position to 0
                    dataItem.animate({
                        key: "deltaPosition",
                        to: 0,
                        duration: 1000,
                        easing: am5.ease.out(am5.ease.cubic),
                    });
                }
            });

            // Sort axis items by index.
            // This changes the order instantly, but as deltaPosition is set,
            // they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function (x, y) {
                return x.get("index") - y.get("index");
            });
        }

        function sortCategoryYAxis() {
            // Sort by value
            series.dataItems.sort(function (x, y) {
                return y.get("graphics").y() - x.get("graphics").y();
            });

            var easing = am5.ease.out(am5.ease.cubic);

            // Go through each axis item
            am5.array.each(yAxis.dataItems, function (dataItem) {
                // get corresponding series item
                var seriesDataItem = getSeriesItem(dataItem.get("category"));

                if (seriesDataItem) {
                    // get index of series data item
                    var index = series.dataItems.indexOf(seriesDataItem);

                    var column = seriesDataItem.get("graphics");

                    // position after sorting
                    var fy =
                        yRenderer.positionToCoordinate(yAxis.indexToPosition(index)) -
                        column.height() / 2;

                    // set index to be the same as series data item index
                    if (index != dataItem.get("index")) {
                        dataItem.set("index", index);

                        // current position
                        var x = column.x();
                        var y = column.y();

                        column.set("dy", -(fy - y));
                        column.set("dx", x);

                        column.animate({
                            key: "dy",
                            to: 0,
                            duration: 600,
                            easing: easing,
                        });
                        column.animate({
                            key: "dx",
                            to: 0,
                            duration: 600,
                            easing: easing,
                        });
                    } else {
                        column.animate({
                            key: "y",
                            to: fy,
                            duration: 600,
                            easing: easing,
                        });
                        column.animate({
                            key: "x",
                            to: 0,
                            duration: 600,
                            easing: easing,
                        });
                    }
                }
            });
            // Sort axis items by index.
            // This changes the order instantly, but as dx and dy is set and animated,
            // they keep in the same places and then animate to true positions.
            yAxis.dataItems.sort(function (x, y) {
                return x.get("index") - y.get("index");
            });
        }

        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        chart.appear(1000, 100);
    }


    return (
        <>
            {props.response && <div id={HBAR_CHART_ID} style={{
                width: '100%',
                height: '500px',
                marginTop: "30px",
                marginLeft: "30px",
                marginRight: "30px"
            }}></div>
            }
        </>
    )
}

export default HBarChart