import styled from "styled-components";

export const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  font-size: 20px;
  margin: 0px 10px 0px;
  flex: 1;
  font-weight: 400;
  .brandName {
    margin-top: 0px;
  }
  .brandDivider{
    height: 40px;
  }
`;
