import axios from "axios";
import constants from "../../constants";
import { getCookie, redirectToPing } from "../../utils/cookie-helper";
export const client = axios.create({
  withCredentials: true,
});
// export const multiclient = axios.all({
//   withCredentials: true,
// });

// Add a request interceptor
client.interceptors.request.use(
  function (config) {
    config.headers.common["Content-Type"] = "application/json";
    const access_token = getCookie("access-token");
    if (access_token && access_token !== "") {
      config.headers.common["authorization"] = "Bearer " + access_token;
    }
    if (sessionStorage.getItem("eosValue") != "" && sessionStorage.getItem("eosValue") != undefined)
      config.headers.common["ta"] = sessionStorage.getItem("eosValue");
    if (sessionStorage.getItem("drugName") != "" && sessionStorage.getItem("drugName") != undefined)
      config.headers.common["drug"] = sessionStorage.getItem("drugName");
    if (sessionStorage.getItem("cycleId") != "" && sessionStorage.getItem("cycleId") != undefined)
      config.headers.common["cycle"] = sessionStorage.getItem("cycleId");
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

/**
 * Request Wrapper with default success/error actions
 */
const request = function (options) {
  const onSuccess = function (response) {
    console.log("Request Successful!", response);
    console.log(response.data);
    console.log(response.status);
    console.log(response.statusText);
    console.log(response.headers);
    console.log(response.config);
    return response.data;
  };

  const handleStatusCode = (statuscode) => {
    switch (statuscode) {
      case 200:
        console.log("ajax.statusCode: 200");
        break;
      case 404:
        console.log("ajax.statusCode: 404");
        break;
      case 500:
        console.log("ajax.statusCode: 500");
        break;
      case 403:
        console.log("ajax.statusCode: 403");
        localStorage.setItem("isPrevState", "TRUE");
        redirectToPing();
        break;
      default:
        break;
    }
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
      handleStatusCode(error.response.status);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

export const GetAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: url,
    withCredentials: false,
    method: "GET",
    ...otherOptions,
  });
};
export const PostAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "POST",
    withCredentials: false,
    body: JSON.stringify(options.data),
    ...otherOptions,
  });
};
export const PutAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "PUT",
    withCredentials: false,
    ...otherOptions,
  });
};
export const DeleteAPIRequest = (options) => {
  const { url, ...otherOptions } = options;
  return request({
    url: constants.api.baseURL + url,
    method: "DELETE",
    withCredentials: false,
    ...otherOptions,
  });
};
export const MultipleGetRequest = (options) => {

  if (options && options.URL1 && options.URL2) {
    axios
      .all([axios.get(options.URL1), axios.get(options.URL2)])
      .then((responseArr) => {
        console.log("Request Successful!", responseArr);
        return responseArr;
      })
      .catch((error) => {
        console.error("Request Failed:", error.config);
        if (error.response) {
          console.error("Status:", error.response.status);
          console.error("Data:", error.response.data);
          console.error("Headers:", error.response.headers);
        } else {
          console.error("Error Message:", error.message);
        }
        return Promise.reject(error.response || error.message);
      });
  }
};

const multirequest = function (options) {
  const onSuccess = function (response) {
    console.log("Request Successful!", response);
    console.log(response.data);
    console.log(response.status);
    console.log(response.statusText);
    console.log(response.headers);
    console.log(response.config);
    return response;
  };

  const handleStatusCode = (statuscode) => {
    switch (statuscode) {
      case 200:
        console.log("ajax.statusCode: 200");
        break;
      case 404:
        console.log("ajax.statusCode: 404");
        break;
      case 500:
        console.log("ajax.statusCode: 500");
        break;
      case 403:
        console.log("ajax.statusCode: 403");
        localStorage.setItem("isPrevState", "TRUE");
        redirectToPing();
        break;
      default:
        break;
    }
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
      handleStatusCode(error.response.status);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };
  return axios.all(options.url).then(onSuccess).catch(onError);

};
export const MultipleGetRequestArray = (options) => {
  const { url, ...otherOptions } = options;
  var obj = {}
  const access_token = getCookie("access-token");
  if (access_token && access_token !== "") {
    obj.authorization = "Bearer " + access_token;
  }
  if (sessionStorage.getItem("eosValue") != "" && sessionStorage.getItem("eosValue") != undefined)
    obj.ta = sessionStorage.getItem("eosValue");
  if (sessionStorage.getItem("drugName") != "" && sessionStorage.getItem("drugName") != undefined)
    obj.drug = sessionStorage.getItem("drugName");
  if (sessionStorage.getItem("cycleId") != "" && sessionStorage.getItem("cycleId") != undefined)
    obj.cycle = sessionStorage.getItem("cycleId");
  const listUrl = [];
  console.log("item url", constants.api.baseURL + options.url[0]);
  options.url.map((item, index) => {
    listUrl.push(axios.get(
      constants.api.baseURL + item,
      {
        headers: obj
      }
    ))
  })
  // options.url.map((item, index) => {
  //   listUrl.push(GetAPIRequest({
  //     url: constants.api.baseURL + item
  //   }))
  // })
  return multirequest({
    url: listUrl,
    ...otherOptions
  })
};

export default request;
