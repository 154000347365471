import { PageHeader, Descriptions, Row, Col, Layout, Typography, Space, Select } from "antd"
import { useEffect, useState, useContext } from "react";
import { GetAPIRequest } from "../../../services";
import CONFIG from "../../../constants";
import Distribution from '../../Distribution/Distribution';
import moment from "moment";
import { useParams, useHistory, } from "react-router-dom";
import HeaderContext from '../../../store/HeaderContext';

const { Title, Text } = Typography;
const { Option } = Select;

const FunnelDetails = (props) => {

    const [tabResponse, setTabResponse] = useState([]);
    const [data, setData] = useState({});

    let { cycleId } = useParams();
    const headerCtx = useContext(HeaderContext);
    const history = useHistory();


    useEffect(() => {
        let funnelData = JSON.parse(sessionStorage.getItem("FunnelData"));
        setData(funnelData[0]);
        getDistributions(cycleId, funnelData[0]);

    }, []);

    function getDistributions(cycleId, funnelData) {

        var url = `${CONFIG.api.getDistributions}/
        ${sessionStorage.getItem("eosValue")}/cycle/${cycleId}/cohortId/${funnelData.id}`
        GetAPIRequest({
            url: url
        }).then((response) => {
            console.log("funnel data", data);
            setTabResponse(response);
        });
    }

    const gotoHome = () => {

        history.goBack();
    }

    return (
        <Layout className="funneldetails-ctn">
            <Row className="back-btn-ctn">
                <Col span={12}>
                {tabResponse &&
                    <PageHeader
                        onBack={gotoHome}
                        ghost={false}
                        // title={data.label}
                        extra={[
                            <span onClick={gotoHome}> BACK</span>
                        ]}
                    >
                        </PageHeader>
                        
                }
                   <div className="header-title"> {data.label}</div>
                      
                </Col>
                <Col span={12}>
                        <Descriptions size="small" column={3} className="details-ctn">
                        <Descriptions.Item label="Date">{moment(sessionStorage.getItem("selectedCycleDate"), 'YYYY-MM-DD').format('DD MMM YYYY')}</Descriptions.Item>
                            <Descriptions.Item label="Drug Area ">{headerCtx.ta}</Descriptions.Item>
                            <Descriptions.Item label="Drug Name">{headerCtx.drug}</Descriptions.Item>
                           
                        </Descriptions>
                    
                    
               </Col>
            </Row >
            <Row>
                <Col span={24} style={{ background: "#FFF" }}>
                    <div className="tabs-ctn">
                    {
                        tabResponse && tabResponse.map((item, index) => {
                            return (
                                <Distribution key={index} url={item.urls} cycleId={cycleId} ></Distribution>
                            );
                        })
                    }
                    </div>
                </Col>
            </Row>
        </Layout >
    )
}

export default FunnelDetails;