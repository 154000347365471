import { Form, Select, Spin, Tabs, Table, Input, Button, Space, Row, Col } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GetAPIRequest } from "../../services";
import D3Funnel from "d3-funnel";
import CONFIG from "../../constants";
import { HomeWrapper, FunnelWrapper, FilterWrapper } from "./Home.styles";
import { useParams, useHistory } from "react-router-dom";
import { Menu, Layout, notification } from 'antd';
import Distribution from '../Distribution/Distribution';
import FunnelDetails from "./FunnelDetails/FunnelDetails";
import HeaderContext from '../../store/HeaderContext';
import TreeView from "../Charts/TreeView";
import { index } from "d3";
import TriggerPlots from "../TriggerPlots";
import { useLayoutEffect } from "react";

const { Option } = Select;
const { TabPane } = Tabs;
const { Header, Content, Sider } = Layout;


const Home = (props) => {
  const [therapeuticalArea, setTherapeuticalArea] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [metrics, setMetrics] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState('');
  const [selectedTherapeuticalArea, setSelectedTherapeuticalArea] = useState(
    null
  );
  const [selectedCycle, setSelectedCycle] = useState('');
  const [selectedCycleDate, setSelectedCycleDate] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("Triggers");
  /*end*/
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const history = useHistory();

  const [selectedFunnel, setSelectedFunnel] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: 700,
    height: undefined,
  });

  const headerCtx = useContext(HeaderContext);

  useEffect(() => {
    console.log("headerCtx", headerCtx)
    getCycles(headerCtx.ta);

    if (sessionStorage.getItem("cycleId"))
      setSelectedCycle(sessionStorage.getItem("cycleId"));

    //getTriggerPlots()
  }, [headerCtx.ta]);

  const [treeView, setTreeView] = useState({});

  const getTreeViewDetails = (selectedCycle) => {

    GetAPIRequest({
      url: `${CONFIG.api.getInsights}/${headerCtx.ta}/cycle/${selectedCycle}/insights/treeview`,
    }).then((response) => {

      setTreeView(response[0])
      //loadTreeView(response[0]);
    });
  }

  function getCycles(ta = "SLE") {
    GetAPIRequest({
      url: `${CONFIG.api.getCycles}/${ta}/cycles`,
    }).then((response) => {
      setIsLoading(false);
      if (response.length > 0) {
        setCycles(response);
        if (sessionStorage.getItem("cycleId")) {
          var cycleDate = response.filter(x => x.cycle_id == sessionStorage.getItem("cycleId"))[0];
          if (cycleDate) {
            sessionStorage.setItem("selectedCycleDate", cycleDate.cycle_dt);
            setSelectedCycleDate(cycleDate.cycle_dt);
            getMetrics(ta, sessionStorage.getItem("cycleId"));
            // getTreeViewDetails(sessionStorage.getItem("cycleId"));
          }
          else {
            sessionStorage.setItem("cycleId", response[0].cycle_id)
            setSelectedCycleDate(response[0].cycle_dt);
            sessionStorage.setItem("selectedCycleDate", response[0].cycle_dt);
            getMetrics(ta, response[0].cycle_id);
            //getTreeViewDetails(response[0].cycle_id);
          }
        }
        else {
          sessionStorage.setItem("cycleId", response[0].cycle_id)
          setSelectedCycleDate(response[0].cycle_dt);
          sessionStorage.setItem("selectedCycleDate", response[0].cycle_dt);
          getMetrics(ta, response[0].cycle_id);
          // getTreeViewDetails(response[0].cycle_id);
        }



        console.log("_response", response);

      }

    });
  }

  function getMetricsByValue(value, event) {
    setSelectedCycle(value);
    getMetrics("SLE", value);
  }

  const [isFunnel, setIsFunnel] = useState(false);
  const [funnelData, setData] = useState([]);

  // const [options, setOptions] = useState({
  //   //width: windowSize.width - 300,
  //   width: 800,
  //   height: 400,
  //   bottomWidth: 1 / 3,
  //   bottomPinch: 1,      // How many sections to pinch
  //   isCurved: true,     // Whether the funnel is curved
  //   curveHeight: 20,     // The curvature amount
  //   fillType: "solid",   // Either "solid" or "gradient"

  //   hoverEffects: true,
  //   chart: {
  //     bottomPinch: 1,
  //     curve: {
  //       enabled: true,
  //     },
  //   },
  //   events: {
  //     click: {
  //       block(event, d) {

  //         gotoDistributions(d);
  //         // let selectedData = funnelData.filter(x => x.label === d.label.raw);
  //         // if (selectedData && selectedData.length > 0) {
  //         //   if (selectedData[0].isClickable) {
  //         //     sessionStorage.setItem("FunnelData", JSON.stringify(selectedData));
  //         //     gotoDistributions(d, selectedCycle);

  //         //   }
  //         // }
  //       }
  //     }
  //   },
  //   tooltip: {
  //     enabled: true,
  //   },
  //   label: {
  //     enabled: true,
  //     //format: '{l}',
  //     format: '{l}\n{f}',
  //   },


  // });

  const [options, setOptions] = useState({
    chart: {
      bottomPinch: 1,
     // animate: 100,
      curve: {
        enabled: true,
      },
      // width: 400,
      minHeight: 300
    },
    block: {
      dynamicHeight: false,
      minHeight: 80,
      fill: {
        type: "gradient",
      },
    },
    events: {
      click: {
        block(event, d) {
          gotoDistributions(d);
          // let selectedData = data.filter(x => x.label === d.label.raw);
          // if (selectedData && selectedData.length > 0) {
          //   if (selectedData[0].isClickable) {
          //     sessionStorage.setItem("FunnelData", JSON.stringify(selectedData));
          //     gotoDistributions(d, cycleId);
          //   }
          // }
          // if (selectedData.length === 1) {
          //   setSelectedFunnel(selectedData[0]);
          // } else {
          //   setSelectedFunnel(null);
          // }
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    label: {
      enabled: true,
      //format: '{l}',
      format: '{l}\n{f}',
    },
  });


  function getMetrics(ta = "SLE", cycleId) {
    setIsLoading(true);
    sessionStorage.setItem("cycleId", cycleId)
    GetAPIRequest({
      url: `${CONFIG.api.getInsights}/${ta}/cycle/${cycleId}/insights`,
    }).then((response) => {

      setSelectedCycle(cycleId);
      //getDistributions(cycleId);
      if (response) {
        response = response.metrics;
        setIsLoading(false);
        setMetrics(response);
        console.log("_response", response);
        var data = [];
        response.map((res) => {
          console.log(res);
          // label: res.label + '\n' + res.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          // res.value = (res.value == null || res.value == 0) ? 1 : res.value;
          if (res.value) {
            data.push({
              id: res.id,
              label: res.label,
              value: res.value,
              isClickable: res.isClickable
            });
          }
        });
        setData(data)
        localStorage.setItem("funneldata", JSON.stringify(data));
        localStorage.setItem("cycleid", cycleId);

        console.log("funnel data", data);

        if (data.length > 0) {
          setIsFunnel(true);
        }
        else {
          setIsFunnel(false);
        }
        // debugger
        // if (options.width > 1000)
        //   options.width = options.width - 300;
        // const chart = new D3Funnel("#funnelContainer");
        // chart.draw(data, options);
      }
      setActiveTabKey("Triggers");

    });
  }

  useEffect(() => {
    if (windowSize.width > 1000)
      windowSize.width = windowSize.width - 300;
    updateFunnelOnResize(windowSize.width);

  }, [windowSize.width, funnelData])

  const updateFunnelOnResize = (width, item) => {

    if (funnelData.length > 0) {
      options.width = width;
      const chart = new D3Funnel("#funnel");
      chart.draw(funnelData, options);
    }

  }

  const getFunnelView = (key, evnet) => {

  }

  const gotoDistributions = (d) => {

    let data = JSON.parse(localStorage.getItem("funneldata"));
    let cycleId = localStorage.getItem("cycleid");
    let selectedData = data.filter(x => x.label === d.label.raw);
    if (selectedData && selectedData.length > 0) {
      if (selectedData[0].isClickable) {
        sessionStorage.setItem("FunnelData", JSON.stringify(selectedData));
        //gotoDistributions(d, selectedCycle);
        history.push("/funneldetails/" + cycleId);

      }
    }

  }


  const [tabsResponse, setTabResponse] = useState([]);

  function getDistributions(cycleId) {

    var url = `${CONFIG.api.getDistributions}/${sessionStorage.getItem("eosValue")}/cycle/${cycleId}`
    GetAPIRequest({
      url: url
    }).then((response) => {
      setActiveTabKey("Triggers");
      localStorage.setItem("cycleId", cycleId);
      setSelectedCycle(cycleId);
      setTabResponse(response);
    });
  }

  const updateSelectedFunnel = () => {
    setSelectedFunnel(null);
    getMetrics(headerCtx.ta, selectedCycle);
  }

  const [triggerPlots, setTriggerPlots] = useState([]);

  const getTriggerPlots = () => {
    GetAPIRequest({
      url: CONFIG.api.getTriggersCycle
    }).then((response) => {
      if (response)
        setTriggerPlots(response)
    })
  }



  useEffect(() => {

    function handleResize() {

      setWindowSize({
        width: window.innerWidth - 300,
        height: window.innerHeight,
      });
      console.log("funneldata", window.innerWidth - 300);
      //  updateFunnelOnResize(window.innerWidth, funnelData)
    }
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <>

      {
        tabsResponse && <HomeWrapper>
          <Layout className="home-container " style={{ backgroundColor: "#fff", width: "100%", padding:"20px" }}>
            {
              selectedFunnel !== null ? (
                <FunnelDetails
                  data={selectedFunnel}
                  cycleId={selectedCycle}
                  cycleDt={selectedCycleDate}
                  updateSelectedFunnel={updateSelectedFunnel}
                  ta={"SLE"}
                  drugName={selectedDrug}
                />
              ) : (
                <div>
                  <Row style={{ textAlign: "right", position: "absolute", right: "0px", zIndex: "9" }}>
                    <Col span={24}>
                      <div className="fliter-ctn">

                        <span>Date : </span>
                        {
                          cycles.length > 0 && <Select

                            placeholder="Search to Select"
                            onChange={(newValue) => {
                              var selectedCycleObj = cycles.filter(x => x.cycle_id == newValue)[0];
                              if (selectedCycleObj) {
                                sessionStorage.setItem("cycleId", selectedCycleObj.cycle_id);
                                sessionStorage.setItem("selectedCycleDate", selectedCycleObj.cycle_dt)
                              }
                              setIsLoading(true);
                              getMetrics(headerCtx.ta, newValue);
                              setTabResponse([]);
                              // getTreeViewDetails(newValue)
                            }}
                            optionFilterProp="children"
                            value={selectedCycle}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }

                          >
                            {cycles.map((cycle, idx) => (
                              <Option key={idx} value={cycle.cycle_id}>
                                {cycle.cycle_dt}
                              </Option>
                            ))}
                          </Select>
                        }
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="tab-ctn">
                        <Spin size="large" spinning={isLoading}>
                          <Tabs  >

                            {/* {
                              triggerPlots.length > 0 &&
                              <TabPane tab="Triggers" key="TriggersPlot">
                                <TriggerPlots triggerPlots={triggerPlots}></TriggerPlots>
                              </TabPane>
                            } */}
                            {
                              <TabPane tab="Home" key="Home" onTabClick={getFunnelView}>
                                {/* <div id="funnelPanel" style={{ width: "700px" }}>
                                  <div id="funnelContainer">
                                    <div id="funnel"></div>
                                  </div>
                                </div> */}
                                <div id="funnel"></div>
                              </TabPane>
                            }
                            {/* <TabPane tab="Triggers Execution" key="TriggersExecution">
                              <TreeView response={treeView} title=""></TreeView>
                            </TabPane> */}
                            {
                              tabsResponse && tabsResponse.map((item, index) => {

                                return (
                                  <TabPane tab={item.category} key={index}  >
                                    <Distribution url={item.urls} cycleId={selectedCycle} ></Distribution>
                                  </TabPane>
                                )
                              })

                            }
                          </Tabs>
                        </Spin>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            }
          </Layout>

        </HomeWrapper >
      }
    </>
  );
};

export default Home;


