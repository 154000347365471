import { Divider, Image } from "antd";
import React from "react";
import AZLOGO from "../../logo-az.png";
import { BrandWrapper } from './Brand.styles';
import { useParams, useHistory } from "react-router-dom";

const Brand = () => {
  const history = useHistory();

  const gotoHome = () => {
    history.push('/')
  }
  return (
    <BrandWrapper>
      <Image className="brandLogo" preview={false} width={120} src={AZLOGO} onClick={() => gotoHome()} />
      <Divider className="brandDivider" type="vertical" />
      <div className="brandName">Dashboard</div>
    </BrandWrapper>
  )
};

export default Brand;
