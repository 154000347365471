
import { Table, Input } from "antd"
import { useEffect, useState } from "react";
const { Search } = Input;


const InsightTable = (props) => {

    const [columns, setColumns] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    const [tableData, setTableData] = useState(props.data);
    const [tableFilterData, setTableFilterData] = useState(props.data);
    useEffect(() => {
        console.log("props", props);
        console.log("table insight", "insight");
        generateColumns();
    }, [props.data])

    const handleChange = (pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setSortedInfo(sorter);
        setFilteredInfo(filters);
        //  generateColumns()
    };


    const generateColumns = () => {
        let keys = Object.keys(props.headers);
        let columnArr = [];

        props.headers.map((item, index) => {

            if (item.isVisible) {

                let columnOnj = {};
                if (item.isPercentage) {
                    columnOnj = {
                        title: item.displayValue,
                        dataIndex: item.key,
                        key: item.key,
                        sorter: item.isSort ? (item.dataType == "string" ? (a, b) => a[item.key].localeCompare(b[item.key])
                            : (a, b) => {
                                return a[item.key].slice(0, -1) - b[item.key].slice(0, -1)
                            }) : false,

                    }
                }
                else {
                    columnOnj = {
                        title: item.displayValue,
                        dataIndex: item.key,
                        key: item.key,
                        sorter: item.isSort ? (item.dataType == "string" ? (a, b) => a[item.key].localeCompare(b[item.key])
                            : (a, b) => {
                                return a[item.key] - b[item.key]
                            }) : false,

                    }
                }
                columnArr.push(columnOnj);
            }

        })
        setColumns(columnArr);
        console.log("columnArr", columnArr);
        let subItemKeys = ""
        if (props.data.length > 0)
            subItemKeys = Object.keys(props.data[0]);
        props.data.map((subitem, subindex) => {

            subItemKeys.map((childitem, index) => {

                let headerObject = props.headers.filter(x => x.key == childitem)[0];
                if (headerObject['isPercentage']) {
                    //let child = subitem[childitem]
                    props.data[subindex][childitem] = props.data[subindex][childitem] + "%"
                }
            })

        })
    }
    const searchByName = (event) => {

        let filterData = []

        if (props.searchableColumns.length > 0 && event.target.value.length > 0) {
            
            props.searchableColumns.map((item, index) => {
                filterData = tableData.filter((column) => {
                    return (
                        column[item].toLowerCase()
                            .includes(event.target.value.toLowerCase())
                    )
                });
            })
            setTableData(filterData)
        }
        else {
            setTableData(tableFilterData)
        }


        console.log("filterData", filterData);

    }
    return (
        <>
            <div style={{
                marginLeft: "30px",
                marginRight: "30px",
            }}>
                {
                    props.isSearch &&
                    <div style={{textAlign:"right"}} >
                        <Search placeholder="search by name" style={{
                            width: 304
                        }}
                            size="large" onKeyUp={(e) => searchByName(e)} enterButton />
                    </div>
                }
                <br />
                <Table
                    dataSource={tableData} columns={columns}
                    onChange={handleChange}
                />
            </div>
        </>
    )
}

export default InsightTable;