import styled from "styled-components";

export const UserInfoWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  color: #666666;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  .userInfoIcon {
    background-color: rgb(132,1,81);
  }
`;
export const User = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex: 1;
  line-height: 20px;
`;

export const UserInfoName = styled.span`
  color: #760041;
`;

export const UserInfoDesignation = styled.span`
  color: #666666;
`;
