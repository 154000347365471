import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";

const StackedBarLineChart = (props) => {


    const STACKEDBARLINECHART_CHART_ID = "StackedBarLineChart-" + props.title.replace(" ", "-");
    const stackedBarLineChart = useRef(null);

    useEffect(() => {
        let chartDiv = am5.Root.new("StackedBarLineChart-" + props.title.replace(" ", "-"));
        stackedBarLineChart.current = chartDiv;
        if (props.response) {
            loadStackedBarLineChart(chartDiv);
        }
        else {
            chartDiv.dispose();
        }
        return () => {
            chartDiv.dispose();
        }
    });

    const loadStackedBarLineChart = (root) => {

        //var data = props.response.data;
        var data = [
            {
                "year": 2016,
                "title": "2016 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 23,
                    "CIT": 16,
                    "CD20": 5,
                    "PI3K": 2
                },
                "total": 46,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2016,
                "title": "2016 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 27,
                    "CIT": 12,
                    "CD20": 5,
                    "PI3K": 2
                },
                "total": 46,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2016,
                "title": "2016 Qtr 3",
                "treatments": {
                    "IBRUTINIB": 24,
                    "CIT": 7,
                    "CD20": 5,
                    "PI3K": 2
                },
                "total": 38,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2016,
                "title": "2016 Qtr 4",
                "treatments": {
                    "IBRUTINIB": 18,
                    "CD20": 8,
                    "CIT": 7,
                    "PI3K": 2
                },
                "total": 35,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2017,
                "title": "2017 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 21,
                    "CD20": 9,
                    "CIT": 8,
                    "PI3K": 2
                },
                "total": 40,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2017,
                "title": "2017 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 20,
                    "CD20": 9,
                    "CIT": 7,
                    "PI3K": 2
                },
                "total": 38,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2017,
                "title": "2017 Qtr 3",
                "treatments": {
                    "IBRUTINIB": 18,
                    "CIT": 12,
                    "CD20": 10,
                    "PI3K": 2,
                    "VENETOCLAX": 1
                },
                "total": 43,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2017,
                "title": "2017 Qtr 4",
                "treatments": {
                    "IBRUTINIB": 17,
                    "CD20": 11,
                    "CIT": 11,
                    "PI3K": 2,
                    "VENETOCLAX": 1
                },
                "total": 42,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2018,
                "title": "2018 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 22,
                    "CIT": 13,
                    "CD20": 9,
                    "PI3K": 2
                },
                "total": 46,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 1,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2018,
                "title": "2018 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 23,
                    "CD20": 13,
                    "CIT": 10,
                    "PI3K": 2
                },
                "total": 48,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 1,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2018,
                "title": "2018 Qtr 3",
                "treatments": {
                    "IBRUTINIB": 23,
                    "CD20": 14,
                    "CIT": 11,
                    "PI3K": 2
                },
                "total": 50,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2018,
                "title": "2018 Qtr 4",
                "treatments": {
                    "IBRUTINIB": 26,
                    "CD20": 13,
                    "CIT": 10,
                    "PI3K": 3
                },
                "total": 52,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2019,
                "title": "2019 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 25,
                    "CIT": 9,
                    "CD20": 7,
                    "PI3K": 3
                },
                "total": 44,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 21,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 2,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2019,
                "title": "2019 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 21,
                    "CD20": 9,
                    "CIT": 7,
                    "PI3K": 3
                },
                "total": 40,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 18,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 1,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2019,
                "title": "2019 Qtr 3",
                "treatments": {
                    "IBRUTINIB": 19,
                    "CD20": 15,
                    "CIT": 6,
                    "PI3K": 1
                },
                "total": 41,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 20,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 5,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2019,
                "title": "2019 Qtr 4",
                "treatments": {
                    "IBRUTINIB": 20,
                    "CD20": 13,
                    "CIT": 5,
                    "PI3K": 1
                },
                "total": 39,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 10,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 5,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2020,
                "title": "2020 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 21,
                    "CD20": 11,
                    "CIT": 4,
                    "PI3K": 1
                },
                "total": 37,
                "activities": {
                    "Display": 5,
                    "Email": 35,
                    "e-newsletters": 90,
                    "in app": 12,
                    "Selling Call": 18,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2020,
                "title": "2020 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 21,
                    "CD20": 10,
                    "CIT": 3,
                    "PI3K": 1
                },
                "total": 35,
                "activities": {
                    "Display": 1,
                    "Email": 59,
                    "e-newsletters": 70,
                    "in app": 28,
                    "Selling Call": 0,
                    "Alerts": 29,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2020,
                "title": "2020 Qtr 3",
                "treatments": {
                    "IBRUTINIB": 18,
                    "CD20": 12,
                    "CIT": 1,
                    "PI3K": 1
                },
                "total": 32,
                "activities": {
                    "Display": 11,
                    "Email": 42,
                    "e-newsletters": 31,
                    "in app": 32,
                    "Selling Call": 1,
                    "Alerts": 11,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 1,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2020,
                "title": "2020 Qtr 4",
                "treatments": {
                    "IBRUTINIB": 18,
                    "CD20": 13,
                    "CIT": 2,
                    "ACALABRUTINIB": 1,
                    "PI3K": 1
                },
                "total": 35,
                "activities": {
                    "Display": 651,
                    "Email": 59,
                    "e-newsletters": 62,
                    "in app": 0,
                    "Selling Call": 5,
                    "Alerts": 8,
                    "Selling": 1,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 2,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2021,
                "title": "2021 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 18,
                    "CD20": 10,
                    "CIT": 3,
                    "ACALABRUTINIB": 1,
                    "LENALIDOMIDE": 1,
                    "PI3K": 1
                },
                "total": 34,
                "activities": {
                    "Display": 764,
                    "Email": 234,
                    "e-newsletters": 39,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 12,
                    "pp_email": 4,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2021,
                "title": "2021 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 16,
                    "CD20": 5,
                    "CIT": 5,
                    "PI3K": 2,
                    "ACALABRUTINIB": 1,
                    "LENALIDOMIDE": 1
                },
                "total": 30,
                "activities": {
                    "Display": 1003,
                    "Email": 390,
                    "e-newsletters": 22,
                    "in app": 21,
                    "Selling Call": 0,
                    "Alerts": 7,
                    "Selling": 18,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 1
                }
            },
            {
                "year": 2021,
                "title": "2021 Qtr 3",
                "treatments": {
                    "IBRUTINIB": 19,
                    "CD20": 5,
                    "CIT": 4,
                    "ACALABRUTINIB": 2,
                    "PI3K": 2
                },
                "total": 32,
                "activities": {
                    "Display": 353,
                    "Email": 103,
                    "e-newsletters": 14,
                    "in app": 1,
                    "Selling Call": 0,
                    "Alerts": 3,
                    "Selling": 0,
                    "pp_email": 2,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2021,
                "title": "2021 Qtr 4",
                "treatments": {
                    "IBRUTINIB": 15,
                    "CD20": 5,
                    "ACALABRUTINIB": 2,
                    "PI3K": 2,
                    "CIT": 1,
                    "ZANUBRUTINIB": 1
                },
                "total": 26,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2022,
                "title": "2022 Qtr 1",
                "treatments": {
                    "IBRUTINIB": 41,
                    "ACALABRUTINIB": 19,
                    "CD20": 4,
                    "VENETOCLAX": 4,
                    "ZANUBRUTINIB": 3,
                    "PI3K": 2,
                    "CIT": 1
                },
                "total": 74,
                "activities": {}
            },
            {
                "year": 2022,
                "title": "2022 Qtr 2",
                "treatments": {
                    "IBRUTINIB": 23,
                    "ACALABRUTINIB": 11,
                    "PI3K": 1,
                    "ZANUBRUTINIB": 1
                },
                "total": 36,
                "activities": {
                    "Display": 0,
                    "Email": 0,
                    "e-newsletters": 0,
                    "in app": 0,
                    "Selling Call": 0,
                    "Alerts": 0,
                    "Selling": 0,
                    "pp_email": 0,
                    "text ad": 0,
                    "Remote Engagement": 0,
                    "Phone - by HCP Request": 0
                }
            },
            {
                "year": 2022,
                "title": "2022 Qtr 3",
                "treatments": {},
                "total": 0,
                "activities": {}
            }
        ]
        var resData = data;
        var treData = [];
        var actData = [];
        var treatments = [];
        var activities = [];
        var defaultTreatments = [
            "CRT",
            "SURGERY",
            "RADIATION",
            "CHEMOTHERAPY",
            "IMFINIZI",
            "IMFINZI+CHEMOTHERAPY",
            "KEYTRUDA",
            "KEYTRUDA+CHEMOTHERAPY",
            "TAGRISSO",
            "TAGRISSO+CHEMO",
        ];

        var profile = localStorage.getItem("SSN_USER_PROFILE");

        if (profile == "CLL") {
            defaultTreatments = [
                "CRT",
                "SURGERY",
                "RADIATION",
                "CHEMOTHERAPY",
                "IMFINIZI",
                "IMFINZI+CHEMOTHERAPY",
                "KEYTRUDA",
                "KEYTRUDA+CHEMOTHERAPY",
                "TAGRISSO",
                "TAGRISSO+CHEMO",
                "ACALABRUTINIB",
                "IBRUTINIB",
                "ZANUBRUTINIB",
                "LENALIDOMIDE",
            ];
        } else if (profile == "NSCLC") {
            defaultTreatments = [
                "CRT",
                "SURGERY",
                "RADIATION",
                "CHEMOTHERAPY",
                "IMFINIZI",
                "IMFINZI+CHEMOTHERAPY",
                "KEYTRUDA",
                "KEYTRUDA+CHEMOTHERAPY",
                "TAGRISSO",
                "TAGRISSO+CHEMO",
            ];
        }

        root.setThemes([am5themes_Animated.new(root)]);

        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                // wheelX: "panX",
                // wheelY: "zoomX",
                layout: root.verticalLayout,
            })
        );

        chart.set(
            "scrollbarX",
            am5.Scrollbar.new(root, {
                orientation: "horizontal",
            })
        );

        data.forEach((d, idx) => {
            for (const [key, value] of Object.entries(d.treatments)) {
                if (treatments.indexOf(key) < 0) {
                    treatments.push(key);
                }
            }
            for (const [key, value] of Object.entries(d.activities)) {
                if (activities.indexOf(key) < 0) {
                    activities.push(key);
                }
            }
        });

        treatments.sort((a, b) => a.localeCompare(b));
        activities.sort((a, b) => a.localeCompare(b));

        data.forEach((d, idx) => {
            var tobj = {
                year: d.title,
            };
            var tr = d.treatments;
            treatments.forEach((t, idx) => {
                if (tr[t]) {
                    tobj[t] = tr[t];
                } else {
                    tobj[t] = 0;
                }
            });
            treData.push(tobj);
        });

        activities.forEach((t, idx) => {
            var aobj = {};
            data.forEach((d, idx) => {
                var act = d.activities;
                if (act[t]) {
                    aobj[t] = act[t];
                } else {
                    aobj[t] = 0;
                }
            });
        });

        console.log("treData", treData);
        console.log("actData", actData);

        var xAxis = chart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "year",
                renderer: am5xy.AxisRendererX.new(root, {}),
                tooltip: am5.Tooltip.new(root, {}),
            })
        );

        xAxis.data.setAll(treData);

        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                max: 100,
                numberFormat: "#'%'",
                strictMinMax: true,
                calculateTotals: true,
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );

        function makeSeries(name, fieldName) {
            var series = chart.series.push(
                am5xy.ColumnSeries.new(root, {
                    name: name,
                    stacked: true,
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: fieldName,
                    valueYShow: "valueYTotalPercent",
                    categoryXField: "year",
                    fillOpacity: 0.9,
                })
            );

            series.columns.template.setAll({
                tooltipText:
                    "{name}, {categoryX}:{valueYTotalPercent.formatNumber('#.#')}%",
                tooltipY: am5.percent(10),
            });
            series.data.setAll(treData);
            if (defaultTreatments.indexOf(name) > -1) {
                series.columns.template.setAll({
                    fillOpacity: 0.9,
                });
                series.appear();
            } else {
                series.columns.template.setAll({
                    fillOpacity: 0.3,
                });
                series.hide();
            }

            series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Label.new(root, {
                        text: "",
                        fill: root.interfaceColors.get("alternativeText"),
                        centerY: am5.p50,
                        centerX: am5.p50,
                        populateText: true,
                    }),
                });
            });

            //legend.data.push(series);
        }

        for (const [key, value] of Object.entries(treData[0])) {
            if (key !== "year") {
                makeSeries(key, key);
            }
        }

        function generateActivityData(activity) {
            console.log("actData", activity);
            var _data = [];
            for (var i = 0; i < data.length; ++i) {
                console.log("actData", data[i]);
                _data.push({
                    year: data[i].title,
                    value: data[i].activities[activity]
                        ? data[i].activities[activity]
                        : 0,
                });
            }
            console.log("actData", _data);
            return _data;
        }

        var yAxis2 = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                min: 0,
                strictMinMax: true,
                renderer: am5xy.AxisRendererY.new(root, {
                    opposite: true,
                }),
            })
        );

        for (var i = 0; i < activities.length; i++) {
            var series3 = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: activities[i],
                    xAxis: xAxis,
                    yAxis: yAxis2,
                    valueYField: "value",
                    categoryXField: "year",
                    legendValueText: "{valueY}",
                })
            );
            var _data = generateActivityData(activities[i]);
            series3.data.setAll(_data);
            series3.strokes.template.setAll({
                strokeWidth: 2,
                templateField: "strokeSettings",
            });
            series3.bullets.push(function () {
                return am5.Bullet.new(root, {
                    locationY: 0.5,
                    sprite: am5.Circle.new(root, {
                        radius: 5,
                        stroke: series3.get("stroke"),
                        strokeWidth: 2,
                        fill: am5.color(0xffffff),
                    }),
                });
            });

            // Make stuff animate on load
            // https://www.amcharts.com/docs/v5/concepts/animations/
            series3.appear();
        }

        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
                behavior: "none",
            })
        );

        cursor.lineY.set("visible", false);

        chart.set(
            "scrollbarX",
            am5.Scrollbar.new(root, {
                orientation: "horizontal",
            })
        );

        chart.set(
            "scrollbarY",
            am5.Scrollbar.new(root, {
                orientation: "vertical",
            })
        );

        var legend = chart.rightAxesContainer.children.push(
            am5.Legend.new(root, {
                paddingLeft: 15,
                height: am5.percent(100),
                verticalScrollbar: am5.Scrollbar.new(root, {
                    orientation: "vertical",
                }),
            })
        );

        legend.itemContainers.template.set("width", am5.p100);

        legend.valueLabels.template.setAll({
            width: am5.p100,
            textAlign: "right",
        });

        legend.data.setAll(chart.series.values);

        chart.appear(1000, 100);

    }
    return <>
        {
            props.response &&
            <div id={STACKEDBARLINECHART_CHART_ID} style={{
                width: '100%',
                height: '500px',
                marginTop: "30px",
                marginLeft: "30px",
                marginRight: "30px"
            }}></div>
        }
    </>
}

export default StackedBarLineChart