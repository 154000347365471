import styled from "styled-components";

export const HomeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const FunnelWrapper = styled.div`
  margin: 10px;
  padding: 40px 10px;
  background: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  #funnel {
    width: 900px;
    max-width: 900px;
  }
`;
export const FilterWrapper = styled.div`
  margin: 5px 10px;
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  .filterBox{
    margin: 0px 10px;  
    width: 200px;
  }
`;

