import styled from 'styled-components';

export const FooterWrapper = styled.div`
 .footer {
    position: fixed;
    z-index: 10000;
    right: 0;
    width: 100%;
    bottom: 0;
    padding: 18px 20px;
    background: #000000;
    color: #666666;
    text-align: left;
  }
`;
