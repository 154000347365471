import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Table, Tabs, Input, Form, Select, Button, Space, Row, Col } from 'antd';
import CONFIG from "../../../constants";
import { GetAPIRequest } from "../../../services";
import { Menu, Layout, notification } from 'antd';
import 'antd/dist/antd.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";





const Piechart = (props) => {
    const PIE_CHART_ID = "pieChart-" + props.title.replace(" ", "-");
    const pieChart = useRef(null);
    useEffect(() => {
        let chartDiv = am4core.create("pieChart-" + props.title.replace(" ", "-"), am4charts.PieChart);

        pieChart.current = chartDiv;
        if (props.response.length > 0)
            loadPieChart();
        else
            chartDiv.dispose();
        return () => {
            chartDiv.dispose();
        };
    }, [props.cycleId])

    function loadPieChart() {
        am4core.ready(function () {

            let chart = pieChart.current;//am4core.create("pieChartId", am4charts.PieChart);

            // Add data
            chart.data = props.response;

            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "name";
            pieSeries.slices.template.stroke = am4core.color("#fff");
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.labels.template.text = "{name}: {value}";
            pieSeries.slices.template.tooltipText = "{category}: {value}";
            // This creates initial animation
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.hiddenState.properties.endAngle = -90;
            pieSeries.hiddenState.properties.startAngle = -90;

            chart.hiddenState.properties.radius = am4core.percent(0);
        })
    }
    return (
        <>
            {props.response && <div id={PIE_CHART_ID} style={{
                width: '100%',
                height: '500px'
            }}></div>
            }
        </>
    )
}

export default Piechart;