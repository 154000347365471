import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { getCookie } from "../../utils/cookie-helper";
import {
  User,
  UserInfoDesignation,
  UserInfoName,
  UserInfoWrapper,
} from "./UserInfo.styles";
const UserInfo = (props) => {
  const [username, setUserName] = useState("");
  const [designation, setDesignation] = useState("");
  useEffect(() => {
    const user = getCookie("userName");
    const jobTitle = getCookie("jobTitle");
    if (user) {
      setUserName(user);
    }
    if (jobTitle) {
      setDesignation(jobTitle);
    }
  }, []);
  return (
    <UserInfoWrapper>
      <User>
        <UserInfoName>{username}</UserInfoName>
        <UserInfoDesignation>{designation}</UserInfoDesignation>
      </User>
      <Avatar className="userInfoIcon" size="large" icon={<UserOutlined />} />
    </UserInfoWrapper>
  );
};

export default UserInfo;
