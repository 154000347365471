import { useEffect, useState, useRef, useLayoutEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Table, Tabs, Input, Form, Select, Button, Space, Row, Col, Spin } from 'antd';
import CONFIG from "../../constants";
import { GetAPIRequest, MultipleGetRequestArray, MultipleGetRequest } from "../../services";
import { Menu, Layout, notification } from 'antd';
import 'antd/dist/antd.css';
import Barchart from "../Home/Barchart/Barchart";
import Piechart from "../Home/PieChart/PieChart"
import InsightTable from "../Insights/InsightTable";
import HBarChart from "../Charts/HBarchart";
import DonutChart from "../Charts/DonutChart/DonutChart";
import ClusteredBarChart from "../Charts/ClusteredBarChart";
import MultiColumnChart from "../Charts/MultiColumnChart";
import StackedBarChart from "../Charts/StackedBarChart";
import RaceBarChart from "../Charts/RaceBarChart";
import ClusteredPieChart from "../Charts/ClusteredPieChart";
import MultiLineSeriesChart from "../Charts/MultiLineSeriesChart";
import StackedBarLineChart from "../Charts/Stackedbarlinechart"
import DrillDownChart from "../Home/DrillDownChart/DrillDownChart";
import { configure } from "@testing-library/react";
import TreeView from "../Charts/TreeView";
import StackedColumnChart from "../Charts/StackedColumnChart/StackedColumnChart";

const { Header, Content, Sider } = Layout;
const { TabPane } = Tabs;
const Distribution = (props) => {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [categoriesLength, setCategoriesLength] = useState([]);
    const [drillDownUrl, setDrillDownUrl] = useState(null);
    const [drillDownUrls, setDrillDownUrls] = useState([]);
    const [drillDownLevels, setDrillDownLevels] = useState([]);
    useEffect(() => {
        getDistributionsByType()
    }, [props.cycleId]);

    useEffect(() => {
        if (drillDownUrl !== null) {
            let tempArr = drillDownUrls;
            tempArr.push(drillDownUrl);
            setDrillDownUrls(tempArr);
            checkDrillDownLevel(drillDownUrl);
        }
    }, [drillDownUrl]);

    const categoriesArr = [];
    function getDistributionsByType() {

        setCategoriesLength(props.url.length);
        setIsLoading(true);
        MultipleGetRequestArray({
            url: props.url
        }).then((response) => {

            response.map((item, index) => {

                // if (item.data.drillDownUrl) {
                //     let obj = {};
                //     obj["id"] = drillDownLevels.length;
                //     obj["title"] = item.config.url.split('?')[1].split("&")[0].split("title=")[1];
                //     obj["level"] = item.config.url.split('?')[0].split("/").pop();
                //     let arrObject = [];
                //     arrObject.push(obj);
                //     setDrillDownLevels(arrObject);

                //     let replacer = `:${item.data.drillDownUrl.split("/:")[1].split("/")[0]}`;
                //     let updatedDrillDownUrl = item.data.drillDownUrl.replace(replacer, item.data.data[0].id);
                //     setDrillDownUrl(updatedDrillDownUrl);
                // }
                categoriesArr.push(item.data);
            });
            setCategories(categoriesArr);
            setIsLoading(false);
        })

    }

    function checkDrillDownLevel(url) {
        setIsLoading(true);
        GetAPIRequest({
            url: `${CONFIG.api.baseURL}${url}`
        }).then((response) => {
            let obj = {};
            obj["id"] = drillDownLevels.length;
            obj["title"] = url.split('?')[1].split("&")[0].split("title=")[1];
            obj["level"] = url.split('?')[0].split("/").pop();
            let arrObject = drillDownLevels;
            arrObject.push(obj);
            obj["currentUrl"] = url;
            setDrillDownLevels(arrObject);

            if (response.drillDownUrl && response.drillDownUrl !== undefined && response.drillDownUrl !== null) {
                let replacer = `:${response.drillDownUrl.split("/:")[1].split("/")[0]}`;
                let updatedDrillDownUrl = response.drillDownUrl.replace(replacer, response.data[0].id);
                setDrillDownUrl(updatedDrillDownUrl);
            }
            setIsLoading(false);
        });
    }

    const chartsData = (item) => {

        switch (item.template) {
            case "tableChart":
                return (<InsightTable data={item.data} headers={item.headers}
                    isSearch={item.isSearch} searchableColumns={item.searchableColumns} />)
            case "barChart":
                return (<Barchart response={item.data} title={item.title} cycleId={props.cycleId} />);
            // if (item.drillDownUrl) {
            //     return (<DrillDownChart item={item} drillDownLevels={drillDownLevels} drillDownUrls={drillDownUrls} />);
            // } else {
            //     return (<Barchart response={item.data} title={item.title} cycleId={props.cycleId} />);
            // }
            case "pieChart":
                return (<Piechart response={item.data} title={item.title} cycleId={props.cycleId} />);
            case "hBarChart":
                return (<HBarChart response={item.data} title={item.title} cycleId={props.cycleId} />);
            case "donutChart":
                return (<DonutChart response={item.data} title={item.title} />);
            case "clusteredBarChart":
                return (<ClusteredBarChart response={item.data} title={item.title} />);
            case "multicolumnchart":
                return (<MultiColumnChart response={item} />);
            case "stackedbarchart":
                return (<StackedBarChart response={item.data} title={item.title} />);
            case "stackedcolumnchart":
                return (<StackedColumnChart response={item.data} title={item.title} />);
            case "raceBarChart":
                return (<RaceBarChart response={item.data} title={item.title} />);
            case "clusteredpiechart":
                return (<ClusteredPieChart response={item.data} title={item.title} />);
            case "multilineserieschart":
                return (<MultiLineSeriesChart response={item.data} title={item.title} />);
            case "stackedbarlinechart":
                return (<StackedBarLineChart response={item.data} title={item.title} />);
            case "treeView":
                return (<TreeView response={item.data} title={item.title} />);
            case "treeView":
                return (<TreeView response={item.data} title={item.title} />);
            default:
                return (<></>);
        }
    }

    return (
        <>
            {
                categories &&
                <div className="inner-tab-list">
                    <Spin spinning={isLoading}>
                        <Tabs centered defaultActiveKey="0" type="card" >
                            {categories && categories.map((item, index) => {

                                return (
                                    <TabPane tab={item.title} key={index}>
                                        {chartsData(item)}
                                    </TabPane>
                                )
                            })
                            }
                        </Tabs>
                    </Spin>
                </div >
            }
        </>

    )
}

export default Distribution;