import { useEffect, useRef } from "react";
import 'antd/dist/antd.css';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const Barchart = (props) => {

    const BAR_CHART_ID = "barChart-" + props.title.replace(" ", "-");
    const barChart = useRef(null);
    useEffect(() => {
        let chartDiv = am4core.create("barChart-" + props.title.replace(" ", "-"), am4charts.XYChart);

        barChart.current = chartDiv;
        if (props.response.length > 0) {
            loadBarChart();
        } else
            chartDiv.dispose();
        return () => {
            chartDiv.dispose();
        };
    }, [props.response])

    function loadBarChart() {
        let chart = barChart.current;// am4core.create("barChartId", am4charts.XYChart);

        // Add data
        chart.data = props.response;

        // Create axes
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        if (chart.data.length > 8) {
            categoryAxis.renderer.labels.template.rotation = -90;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.adapter.add("dx", function (dx, target) {
                return -target.maxRight / 2;
            });
        }

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

        // Create series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "value";
        series.dataFields.categoryX = "name";
        series.name = "Visits";
        series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
        series.columns.template.fillOpacity = .8;
        series.columns.configField = chart.data;
        series.columns.template.fill = am4core.color("#830059").lighten(0.25); 
        series.stroke = am4core.color("#830059").lighten(0.25);

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

        // series.columns.template.adapter.add("fill", function(fill, target) {
        //     debugger
        //     var color = props.response.colors;

        //    // return color[fieldName] == undefined ? "#6e161f" : color[fieldName];
        //     return chart.colors.getIndex(target.dataItem.index);
        //   })

        if (props.drillDownUrl !== null) {
            series.columns.template.events.on("hit", function (ev) {
                let replacer = props.drillDownUrl.split("/:")[1].split("/")[0];
                let updatedDrillDownUrl = props.drillDownUrl.replace(`:${replacer}`, ev.target.dataItem._dataContext.id);
                barChart.current.dispose();
                props.getDrillChart(updatedDrillDownUrl, props.drillDownUrl.split('?')[0].split("/").pop());
            }, this);
        }
    }

    return (
        <>
            {props.response &&
                <>
                    <div id={BAR_CHART_ID} style={{
                        width: '100%',
                        height: '500px',
                    }}></div>
                </>
            }
        </>
    )
}

export default Barchart;