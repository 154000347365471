import { Breadcrumb, Layout } from "antd";
import { Component, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route, Switch, Redirect, Link
} from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./containers/Home/Home";
import { isAuthenticated, redirectToPing } from "./utils/cookie-helper";
import HeaderContext from './store/HeaderContext'
import FunnelDetails from "./containers/Home/FunnelDetails/FunnelDetails";
import { withRouter } from 'react-router-dom'

const { Content } = Layout;

const App = ({ history }) => {
  const [isValidUser, setIsValidUser] = useState(false);
  const [isTaAndDrugDisable, setIsTaAndDrugDisable] = useState(false);


  useEffect(() => {
    if (isAuthenticated()) {
      setIsValidUser(true);
    } else {
      redirectToPing();
    }

  }, [isTaAndDrugDisable]);
  const PrivateRoute = ({ component: Component, roles, ...rest }) => (

    <Route
      {...rest}
      render={(props) => {

        if (props.history != undefined) {
          var locationName = props.history.location;
          if (locationName == "/")
            setIsTaAndDrugDisable(false);
          else
            setIsTaAndDrugDisable(true)
          props.history.listen((location) => {
            if (location.pathname == "/") {
              setIsTaAndDrugDisable(false)
            }
            else
              setIsTaAndDrugDisable(true)
          })
        }
        return <Component {...props} />;
        // return isAuthenticated() ? (
        //   <Component {...props} />
        // ) : (
        //   redirectToPing()
        // )

      }}
    />
  );

  const [selectedDrug, setSelectedDrug] = useState('');
  const [selectedTherapeuticalArea, setSelectedTherapeuticalArea] = useState("SLE");

  const getHeaderComponentValues = (ta, drug) => {
    setSelectedDrug(drug);
    setSelectedTherapeuticalArea(ta);
  }



  return (
    <>
      {isValidUser && (
        <HeaderContext.Provider value={{
          ta: selectedTherapeuticalArea,
          drug: selectedDrug
        }}>
          < Router >
            <Layout className="layout">
              <Header getHeaderComponentValues={getHeaderComponentValues} isTaAndDrugDisable={isTaAndDrugDisable} />
              <Content
                style={{ padding: "64px 0px", minHeight: window.innerHeight }}
              >
                <Breadcrumb >
                  <Breadcrumb.Item style={{ padding: "16px 0" }}></Breadcrumb.Item>
                </Breadcrumb>
                <div className="site-layout-content">
                  {selectedDrug && <Switch>
                    <Route exact path="/" component={Home} />
                    <PrivateRoute exact path="/funneldetails/:cycleId" component={FunnelDetails} />
                    {/* <PrivateRoute exact path="/distribution/:cycleid/:distributionid" component={Distribution} /> */}
                  </Switch>
                  }
                </div>
              </Content>
              <Footer />
            </Layout>
          </Router></HeaderContext.Provider>
      )}
    </>
  );
};

export default App;
