import { useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5percent from "@amcharts/amcharts5/percent";


const ClusteredPieChart = (props) => {
    const CLUSTEREDPIE_CHART_ID = "ClusteredPieChart-" + props.title.replace(" ", "-");
    const clusteredPieChart = useRef(null);
    useEffect(() => {
        let root = am5.Root.new("ClusteredPieChart-" + props.title.replace(" ", "-"));
        clusteredPieChart.current = root;

        if (props.response.length > 0)
            loadClusteredPieChart(root);
        else
            clusteredPieChart.dispose();

        return () => {
            clusteredPieChart.dispose();
        }
    }, []);

    const loadClusteredPieChart = (root) => {
        let _data = [
            {
                "name": "ARIZONA ONCOLOGY",
                "data": [
                    {
                        "name": "M",
                        "count": 25
                    },
                    {
                        "name": "F",
                        "count": 23
                    }
                ]
            },
            {
                "name": "FLORIDA CANCER SPECIALISTS AND RESEARCH INSTITUTE",
                "data": [
                    {
                        "name": "M",
                        "count": 75
                    },
                    {
                        "name": "F",
                        "count": 49
                    }
                ]
            }
        ]
        var sdaObj = {};
        _data.forEach((d1, i) => {
            if (d1.data && d1.data.length > 0) {
                d1.data.forEach((d2) => {
                    if (sdaObj[`${d2.name}`]) {
                    } else {
                        sdaObj[`${d2.name}`] = {};
                    }
                    var _c = d2.count;

                    if (true && _c > 1) {
                        _c = Math.ceil(d2.count);
                    }
                    sdaObj[`${d2.name}`][`${d1.name}`] = _c;
                });
            }
        });
        var data = [];
        for (const [key, value] of Object.entries(sdaObj)) {
            console.log(`${key}: ${value}`);
            var _data23Obj = {};
            var _data24 = [];
            var _data23Objval = 0;
            for (const [vkey, value1] of Object.entries(value)) {
                var _data23Obj1 = {};
                _data23Obj1.category = vkey;
                _data23Obj1.value = value1;
                _data24.push(_data23Obj1);
                _data23Objval += value1;
            }
            _data23Obj.category = key;
            _data23Obj.value = _data23Objval;
            _data23Obj.breakdown = _data24;
            data.push(_data23Obj);
        }

        console.log("drillDownPieChart-data", data);

        root.setThemes([am5themes_Animated.new(root)]);

        let container = root.container.children.push(
            am5.Container.new(root, {
                width: am5.p100,
                height: am5.p100,
                layout: root.horizontalLayout,
            })
        );

        let pieChart = container.children.push(
            am5percent.PieChart.new(root, {
                width: am5.percent(30),
                innerRadius: am5.percent(50),
            })
        );
        let currentSlice;
        let pieSeries = pieChart.series.push(
            am5percent.PieSeries.new(root, {
                valueField: "value",
                categoryField: "category",
            })
        );
        pieSeries.slices.template.setAll({
            templateField: "sliceSettings",
            strokeOpacity: 0,
        });
        pieSeries.slices.template.on("active", function (active, slice) {
            if (currentSlice && currentSlice != slice && active) {
                currentSlice.set("active", false);
            }

            var color = slice.get("fill");

            label2.setAll({
                fill: color,
                text: root.numberFormatter.format(
                    slice.dataItem.get("valuePercentTotal"),
                    "#.##'%'"
                ),
            });

            label1.set("text", slice.dataItem.get("category"));

            columnSeries.columns.template.setAll({
                fill: slice.get("fill"),
                stroke: slice.get("fill"),
            });

            columnSeries.data.setAll(slice.dataItem.dataContext.breakdown);
            xAxis.data.setAll(slice.dataItem.dataContext.breakdown);

            currentSlice = slice;
        });
        pieSeries.labels.template.set("forceHidden", true);
        pieSeries.ticks.template.set("forceHidden", true);

        var legend = pieChart.seriesContainer.children.push(
            am5.Legend.new(root, {
                centerY: am5.percent(100),
                y: am5.percent(100),
                marginTop: 15,
                marginBottom: 15,
            })
        );

        legend.data.setAll(pieSeries.dataItems);

        let label1 = pieChart.seriesContainer.children.push(
            am5.Label.new(root, {
                text: "",
                fontSize: 20,
                fontweight: "bold",
                centerX: am5.p50,
                centerY: am5.p50,
            })
        );

        let label2 = pieChart.seriesContainer.children.push(
            am5.Label.new(root, {
                text: "",
                fontSize: 12,
                centerX: am5.p50,
                centerY: am5.p50,
                dy: 30,
            })
        );

        pieSeries.events.on("datavalidated", function () {
            //pieSeries.slices.getIndex(0).set("active", true);
        });

        let columnChart = container.children.push(
            am5xy.XYChart.new(root, {
                width: am5.percent(70),
                panX: false,
                panY: false,
                wheelX: "none",
                wheelY: "none",
                layout: root.verticalLayout,
            })
        );
        let xAxis = columnChart.xAxes.push(
            am5xy.CategoryAxis.new(root, {
                categoryField: "category",
                renderer: am5xy.AxisRendererX.new(root, {}),
            })
        );

        let yAxis = columnChart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                extraMax: 0.2,
                renderer: am5xy.AxisRendererY.new(root, {}),
            })
        );
        let columnSeries = columnChart.series.push(
            am5xy.ColumnSeries.new(root, {
                //name: name,
                name: "",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: "value",
                categoryXField: "category",
            })
        );
        columnSeries.columns.template.setAll({
            tooltipText: "{categoryX}: {valueY}",
        });
        columnSeries.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationY: 1,
                locationX: 0.5,
                sprite: am5.Label.new(root, {
                    centerX: am5.p50,
                    text: "{valueY}",
                    populateText: true,
                }),
            });
        });

        columnChart.appear(1000, 100);

        pieSeries.data.setAll(data);
        var slice = pieSeries.slices.getIndex(0);
        var color = slice.get("fill");

        label2.setAll({
            fill: color,
            text: "",
        });

        label1.set("text", slice.dataItem.get("category"));
        columnSeries.columns.template.setAll({
            fill: slice.get("fill"),
            stroke: slice.get("fill"),
        });
        columnSeries.data.setAll(data[0].breakdown);
        columnSeries.columns.template.onPrivate("height", function (
            height,
            target
        ) {
            am5.array.each(target.dataItem.bullets, function (bullet) {
                bullet.set("locationY", 1);
                bullet.get("sprite").set("centerY", am5.p100);
            });
        });
        xAxis.data.setAll(data[0].breakdown);
        xAxis.get("renderer").labels.template.setAll({
            oversizedBehavior: "wrap",
            maxWidth: 150,
            fontSize: 12,
            textAlign: "center",
            ellipsis: "...",
        });
    }


    return (
        <> {
            props.response &&
            <div id={CLUSTEREDPIE_CHART_ID} style={{
                width: '100%',
                height: '500px'
            }}></div>
        }
        </>
    )
}

export default ClusteredPieChart;